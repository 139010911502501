.howItWorksContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  width: 100%;
}

@media (min-width: 768px) {
  .section {
      flex-direction: row;
  }
}

.imageContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}

.textContainer {
  flex: 1;
  padding: 20px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.button {
  padding: 10px 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 20px;
}

.iphoneScreenContainer {
  width: 320px; /* Adjust width as needed */
  height: 600px; /* Adjust height as needed */
  position: relative;
  overflow: hidden;
}

.iphoneScreen {
  position: relative;
  width: 100%;
  height: 100%;
}

.imageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease-in-out, z-index 0s ease-in-out 0.5s;
}

.active {
  transform: translateX(0) !important;
  z-index: 2 !important;
}

.backgroundImage {
  width: 280px; /* Slightly smaller than container width */
  height: 580px; /* Slightly smaller than container height */
  object-fit: cover;
  border-radius: 20px;
  transform: rotate(-10deg) translate(-30px, 20px);
  opacity: 0.7;
  z-index: 1;
}

.activeImage {
  width: 280px; /* Slightly smaller than container width */
  height: 580px; /* Slightly smaller than container height */
  object-fit: cover;
  border-radius: 20px;
  transition: transform 0.5s ease-in-out;
}

.arrows {
  display: flex;
  gap: 40px;
  margin-top: 10px; /* Adjust margin as needed */
}

@media (max-width: 768px) {
  .arrows {
      justify-content: center;
      gap: 20px;
      width: 100%;
      padding: 0 20px;
  }

  .textContainer {
      padding: 10px; /* Reduce padding for smaller screens */
  }

  .section {
      margin: 10px; /* Reduce margin for smaller screens */
  }

  h2 {
      font-size: 1.5rem; /* Adjust font size for smaller screens */
  }

  p {
      font-size: 0.875rem; /* Adjust font size for smaller screens */
  }

  .imageContainer {
      margin-bottom: 0;
  }

  .iphoneScreenContainer {
      width: 240px; /* Adjust width for smaller screens */
      height: 450px; /* Adjust height for smaller screens */
  }

  .backgroundImage,
  .activeImage {
      width: 200px; /* Adjust width for smaller screens */
      height: 400px; /* Adjust height for smaller screens */
  }

  .arrow {
      font-size: 1.5rem; /* Adjust arrow size for smaller screens */
      width: 30px; /* Adjust arrow size for smaller screens */
      height: 30px; /* Adjust arrow size for smaller screens */
  }
}

.arrow {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  color: #333;
  transition: color 0.3s ease;
  border: 2px solid #ddd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow:hover {
  color: #000;
  border-color: #000;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

footer {
    background-color: #1d1d1d;
    position: relative;
    width: 100%;
    min-height: 350px;
    padding: 3rem 1rem;
}

.container {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.col {
    min-width: 250px;
    color: #f2f2f2f2;
    font-family: 'Poppins', sans-serif;
    padding: 0 2rem;
}

.col .logo {
    width: 100px;
    margin-bottom: 25px;
}

.col h3 {
    color: #fff;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
}

.col h3::after {
    content: '';
    height: 3px;
    width: 0px;
    background-color: #ff014fff;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.3s ease;
}

.col h3:hover::after {
    width: 30px;
}

.col .social a {
    color: #fff;
    margin-top: 2rem;
    margin-right: 5px;
    transition: 0.3s ease; 
    height: 20px;
}

.col .social a:hover {
    transform: scale(1.5);
    filter: grayscale(25);
}

.col .links a {
    display: block;
    text-decoration: none;
    color: #9f9f9f;
    margin-bottom: 5px;
    position: relative;
    transition: 0.3s ease;
}

.col .links a::before {
    content: '';
    height: 16px;
    width: 3px;
    position: absolute;
    top: 5px;
    left: -10px;
    background-color: #9f9f9f;
    transition: 0.5s ease;
    opacity: 0;
}

.col .links a:hover::before {
    opacity: 1;
}

.col .links a:hover {
    transform: translateX(-8px);
    color: #fff;
}

.col .contact-details {
    display: inline-flex;
    justify-content: space-between;
}

.col .contact-details i {
    margin-right: 15px;
}

.row .form {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
}

.row .form input {
    background-color: #1a1c20ff;
    border: 0;
    outline: none;
    padding: 14px 20px;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.form button {
    padding: 14px 20px;
    border: 0;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #ff014fff;
}

/********** Responsive Design ********/
@media (max-width: 900px) {
    .row {
        flex-direction: column;
    }
    .col {
        width: 100%;
        text-align: left;
        margin-bottom: 25px;
    }
}

@media (max-width: 768px) {
    .row {
        flex-direction: column;
    }
    .col {
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
    }
}

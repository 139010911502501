.container {
    padding: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Ensures columns stack on smaller screens */
  }
  
  .leftColumn {
    flex: 0 0 45%; /* Adjusted width for left column */
    padding-right: 20px;
  }
  
  .title {
    font-weight: bold;
  }
  
  .header {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .description {
    font-size: 1.2rem;
  }
  
  .rightColumn {
    flex: 0 0 50%; /* Adjusted width for right column */
    background-color: #f8f8f8;
    padding: 30px;
    border-radius: 10px;
  }
  
  .form {
    width: 100%;
  }
  
  .formRow {
    display: flex;
    margin-bottom: 15px;
    gap: 10px; /* Adds space between form groups */
  }
  
  .formGroup {
    flex: 1;
  }
  
  .input {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    margin-bottom: 10px;
  }
  
  .textarea {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    margin-bottom: 15px;
  }
  
  .inputSubmit {
    position: relative;
  }
  
  .submitBtn {
    width: 100%;
    height: 60px;
    background: #222;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: .3s;
    color: white;
  }
  
  .inputSubmit label {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  
  .submitBtn:hover {
    background: #000;
    transform: scale(1.05, 1);
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column; /* Stacks columns vertically on small screens */
    }
  
    .leftColumn, .rightColumn {
      flex: 1 0 100%; /* Ensures columns take full width */
      padding: 20px;
    }
  
    .formRow {
      flex-direction: column; /* Stacks form inputs vertically */
    }
  }
  
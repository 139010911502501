@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.faqPage {
  background-color: #f2f2f2; /* Light gray background */
}

.faqContainer {
  max-width: 800px;
  margin: 0 auto;
  /* background: #fff; */
  padding: 30px;
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

.faqContainer h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.faqContainer p {
  font-size: 16px;
  color: #555;
  text-align: center;
  margin-bottom: 30px;
}

.faqContainer a {
  color: #1890ff;
}

.accordion {
  width: 100%;
}

.panel {
  border-bottom: 1px solid #ddd;
}

.panelHeader {
  padding: 15px;
  font-weight: 600;
  cursor: pointer;
  background-color: #f7f0d3;
  transition: background-color 0.3s ease;
  position: relative; /* Added for arrow positioning */
}

.panelHeader:hover {
  background-color: #f0f0f0;
}

.panelContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
}

.panelContent.open {
  max-height: 500px; /* You can set this to a higher value to fit your content */
  transform: scaleY(1);
  opacity: 1;
}

.panelContent p {
  padding: 15px;
  margin: 0;
}

.arrow {
  float: right;
  transition: transform 0.3s ease, background-color 0.3s ease;
  width: 24px;
  height: 24px;
  background: #555;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow:hover {
  background: #000;
}

.arrowActive {
  transform: rotate(180deg);
}

.arrow svg {
  width: 16px;
  height: 16px;
}

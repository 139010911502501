.aboutUs {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .section {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .section.reverse {
    flex-direction: row-reverse;
  }
  
  .text {
    flex: 1;
    padding: 20px;
  }
  
  .text h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .text p {
    font-size: 1.2rem;
    color: #4a4a4a;
    line-height: 1.6;
  }
  
  .image {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .section {
      flex-direction: column;
    }
  
    .section.reverse {
      flex-direction: column;
    }
  
    .text h2 {
      font-size: 2rem;
    }
  
    .text p {
      font-size: 1rem;
    }
  }
  
.termsContainer {
    padding: 5rem 1rem;
    background-color: #f8f8f8;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .section {
    text-align: center;
    background-color: #fff;
    padding: 3rem 1rem;
    margin-bottom: 2rem;
    border-radius: 8px;
  }
  
  .section h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .section h2 {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .section p {
    font-size: 1.2rem;
    color: #4a4a4a;
    line-height: 1.6;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .section button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .section button:hover {
    background-color: #444;
  }
  
.inputBox .inputField {
    width: 100%;
    height: 60px;
    font-size: 17px;
    padding: 0 25px;
    margin-bottom: 15px;
    border-radius: 30px;
    border: none;
    box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.05);
    outline: none;
    transition: .3s;
}
::placeholder {
    font-weight: 500;
    color: #222;
}
.inputField:focus {
    width: 105%;
}

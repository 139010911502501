.navbar {
    background: #f8f8f8;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 9999; /* Ensure it's on top */
    width: 100%;
  }
  
  .navbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    z-index: 1;
    width: 100%;
    max-width: 1500px;
    padding: 0 24px;
  }
  
  .navbarLogo {
    color: #000;
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }
  
  .menuIcon {
    display: none;
  }
  
  .navMenu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
  }
  
  .navItem {
    height: 80px;
  }
  
  .navLinks {
    color: #000;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    transition: border-bottom 0.3s;
  }
  
  .navLinks:hover {
    border-bottom: 3px solid #000;
  }
  
  @media screen and (max-width: 960px) {
    .menuIcon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .navMenu {
      display: flex;
      flex-direction: column;
      width: 50%; /* Set width to 50% */
      height: calc(100vh - 40px); /* Make it almost full height */
      position: fixed; /* Use fixed position */
      top: 20px; /* Create space at the top */
      right: -100%; /* Hide it off-screen initially */
      opacity: 1;
      transition: all 0.5s ease;
      background: #fff;
      z-index: 9999; /* Ensure it stays above other content */
      border-radius: 10px; /* Optional: add some border radius for aesthetics */
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: add some shadow for better visibility */
      padding: 1rem;
    }
  
    .navMenu.active {
      right: 0; /* Slide in from the right */
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 9999;
    }
  
    .closeIcon {
      align-self: flex-end;
      font-size: 1.8rem;
      cursor: pointer;
      margin-bottom: 1rem;
    }
  
    .navItem {
      height: 80px;
    }
  
    .navLinks {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .navLinks:hover {
      border: none;
    }
  }
  
/* Home.module.css */
.homeContainer {
    position: relative;
    width: 100%;
    height: 60vh; /* Full viewport height */
    background: url('./img/sticker.jpeg') no-repeat center center/cover;
}

.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
}

.overlay h1, .overlay p {
    text-shadow: 
        -1px -1px 0 #000,  
         1px -1px 0 #000,
        -1px  1px 0 #000,
         1px  1px 0 #000;
}

.overlay h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.overlay p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}


.whiteBgSection {
    background-color: #fff; /* White background */
    padding: 50px 20px;
}
.grayBgSection {
    background-color: #f2f2f2; /* Light gray background */
    padding: 50px 20px;
}
  
  .overlay h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .overlay p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .overlay button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .overlay button:hover {
    background-color: #444;
  }
  
  .section {
    padding: 5rem 1rem;
    text-align: center;
    background-color: #f8f8f8;
  }
  
  .section h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .section p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .section button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .section button:hover {
    background-color: #444;
  }
  @keyframes pulseAnim {
    0% {
      transform: scale(1);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.4;
    }
    100% {
      transform: scale(1);
      opacity: 0.7;
    }
  }
  
  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
  }
  
  .pulse {
    z-index: 0;
    position: fixed;
    content: '';
    bottom: 35px;
    right: 35px;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background-color: #25d366;
    animation-name: pulseAnim;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
  
  .myFloat {
    margin-top: 16px;
  }
  
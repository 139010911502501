  body, html, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .App {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100vh; /* Ensure it covers the viewport height */
  }

  #overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    flex-direction: column;
    text-align: center;
    transition: opacity 0.5s ease-in-out;
  }

  .instructions {
    padding: 20px;
    color: white;
    text-shadow: 1px 1px 2px black;
    max-width: 90%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    animation: fadeIn 1s ease-in-out;
  }

  .instructions h2 {
    margin-bottom: 20px;
    font-size: 28px;
    color: #ffeb3b;
    text-transform: uppercase;
  }

  .instructions p {
    margin-bottom: 10px;
    font-size: 18px;
    color: #ffeb3b;
  }

  .instructions ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }

  .instructions ul li {
    margin-bottom: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .instructions ul li::before {
    content: '✔️';
    margin-right: 8px;
    font-size: 18px;
    color: #76ff03;
  }

  #startButton {
    background-color: #ffeb3b;
    border: none;
    color: black;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s, transform 0.3s;
    position: relative;
    top: 100px; /* Adjust this value to position the button below the navbar */
    z-index: 5; /* Ensure it's below the navbar */
  }

  #startButton:hover {
    background-color: #cddc39;
    transform: scale(1.1);
  }

  .button {
    background-color: #333333; /* Dark gray */
    border: none;
    color: white; /* White text */
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 8px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }

  .button:hover, .button:focus {
    background-color: #444444; /* Slightly lighter gray on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    transform: translateY(-2px);
  }

  .button:active {
    background-color: #222222; /* Even darker on active */
    transform: translateY(1px);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  }

  .controls {
    position: fixed;
    top: 10px;
    width: 100%;
    text-align: center;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .controls .button {
    margin: 4px 10px;
    padding: 8px 12px;
    font-size: 12px;
  }

  @media (max-width: 768px) {
    .controls .button {
        font-size: 12px;
        padding: 6px 10px;
    }

    #startButton {
        font-size: 16px;
        padding: 8px 16px;
    }

    .instructions h2 {
        font-size: 24px;
    }

    .instructions p, .instructions ul li {
        font-size: 14px;
    }
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  #overlay.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-toggle {
    background-color: #333333;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .dropdown-toggle:hover {
    background-color: #444444;
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 200px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
    margin-top: 8px;
    padding: 0;
    overflow: hidden;
  }

  .dropdown-menu li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .dropdown-menu li:hover {
    background-color: #343434; /* Lighter gray for hover */
  }

  .dropdown .dropdown-menu {
    display: block;
  }

.container {
    padding: 50px;
    background-color: #f2f2f2;
    border-radius: 10px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    direction: rtl; /* Ensures right-to-left text direction */
  }
  
  .introText {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .instructions {
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #555;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  .label {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #333;
    display: block;
  }
  
  .input,
  .textarea {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .textarea {
    height: 100px;
    resize: vertical;
  }
  
  .input[type="file"] {
    padding: 10px;
    font-size: 1rem;
  }
  
  .inputSubmit {
    margin-top: 30px;
  }
  
  .submitBtn {
    width: 100%;
    height: 60px;
    background: #222;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.3s;
    color: white;
    font-size: 1.2rem;
  }
  
  .submitBtn:hover {
    background: #000;
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 20px;
    }
  
    .introText {
      font-size: 1.3rem;
    }
  
    .instructions {
      font-size: 1rem;
      margin-bottom: 20px;
    }
  
    .input,
    .textarea {
      padding: 10px;
      font-size: 0.9rem;
    }
  
    .submitBtn {
      height: 50px;
      font-size: 1rem;
    }
  }
  